import React, { createContext, useState, useEffect, useContext } from 'react';
/* eslint-disable */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NeedsContext } from '../needs/NeedsContext';
import { PlanningNeedsContext } from '../needs/PlanningNeedsContext';
import { selectActivityCalendarSlice, selectScale } from '../../redux/slices/slicing/activity-calendar.slice';
import { DEFAULT_CHART_OPTIONS, selectActivityCalendarTemporalNeedSliceDictionary, selectChartOptions, selectNeedList, selectShouldUpdateChartData, setChartOptions } from '../../redux/slices/chart/need-chart.slice';
import computeChartData, { computeActivityCalendarTemporalSliceDictionary, updateNeedList } from '../../redux/thunks/need-chart-slice.thunk';
import { selectTabPreferences } from '../../redux/slices/app/tab.slice';
import { timer } from '../../helpers/slicing-helper';




const HistogramChartContext = createContext();

const HistogramChartContextProvider = ({ children }) => {
    const dispatch = useDispatch();	
    const globalNeedList = useContext(NeedsContext).NeedList;
    const planningNeedList = useContext(PlanningNeedsContext).NeedList;
    const activityCalendarSliceDictionary = useSelector(selectActivityCalendarSlice);
    const tabPreferences = useSelector(selectTabPreferences);
    const chartOptions = useSelector(selectChartOptions);
    const needList = useSelector(selectNeedList);
    const scale = useSelector(selectScale);
    const shouldUpdateChartData = useSelector(selectShouldUpdateChartData);
    const activityCalendarTemporalNeedSliceDictionary = useSelector(selectActivityCalendarTemporalNeedSliceDictionary);
    // Used for drawing chart yAxis which is a different instance of recharts
    const [chartRef, setChartRef] = useState(null);

    useEffect(() => {
        if (tabPreferences && tabPreferences?.gantt_histogram_options) {
            dispatch(setChartOptions({...DEFAULT_CHART_OPTIONS, ...tabPreferences.gantt_histogram_options}));
        } else {
            dispatch(setChartOptions(DEFAULT_CHART_OPTIONS));
        }
    }, []);

    useEffect(() => {
        if (chartOptions) {
            dispatch(updateNeedList())
        }
    }, [
        chartOptions?.needType,
        globalNeedList,
        planningNeedList
    ]);

    useEffect(() => {
        if (activityCalendarSliceDictionary && chartOptions && scale) {
            timer('computeActivityCalendarTemporalSliceDictionary', () => {
                dispatch(computeActivityCalendarTemporalSliceDictionary());
            });
        }
    }, [activityCalendarSliceDictionary, chartOptions?.sliceDataType, chartOptions?.effortTimeUnitId, scale]);

    useEffect(() => {
        if (activityCalendarTemporalNeedSliceDictionary && Object.values(activityCalendarTemporalNeedSliceDictionary).length > 0 && shouldUpdateChartData && chartOptions) {
            console.log('coucou')
            dispatch(computeChartData())
        }
    }, [activityCalendarTemporalNeedSliceDictionary,
        shouldUpdateChartData,
        needList,
        chartOptions?.isGanttActivityFiltered])
    return (
        <HistogramChartContext.Provider
            value={{
                chartRef,
                setChartRef,
            }}
        >
            {children}
        </HistogramChartContext.Provider>
    );
};

HistogramChartContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { HistogramChartContext, HistogramChartContextProvider };
