import * as Sentry from '@sentry/browser';
import { sortBy } from 'lodash';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Spin,
    Collapse,
    Slider,
    Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UnlockOutlined, LockOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import ActivityService from '../../../services/activity.service';
import CustomFieldService from '../../../services/custom-field.service';
import { notificationError, requestError } from '../../../helpers/notification';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import quantityUnits from '../activity_config';
import { ACTIVITY_STATE, formatGanttTask } from '../../../helpers/planning';
import { ALL_JOBS, JOB_FIELD } from '../../../constants/Jobs';
import DefaultUnifiedFieldId from '../../../constants/DefaultPlanningField';
import { selectTabPreferences } from '../../../redux/slices/app/tab.slice';
import { incrementReRenderGantt, selectGanttData } from '../../../redux/slices/app/planning.slice';
import { refetchCalendars } from '../../../redux/thunks/calendar-slice.thunk';
import { updateActivitiesDictionary } from '../../../redux/thunks/activity-slice.thunk';
import { getYieldUnit } from '../gantt_utils';

const { Panel } = Collapse;
const DrawerRow = styled(Row)`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
    }
    
`;

const ActivityDetailsTab = ({ activity, cannotEdit, computeCannotEdit }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('translation', {keyPrefix:'activity_tab'})
    const fieldTranslate = useTranslation('translation', {keyPrefix:'columns'}).t
    const translate = useTranslation().t
    const [calendars, setCalendars] = useState([]);
    // const [timeUnits, setTimeUnits] = useState([]);
    const [saving, setSaving] = useState(false);
    const [taskProgress, setTaskProgress] = useState(activity.progress * 100);
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const {planningCustomFields,
        allCustomFields,
        activitiesDictionary,
        planningSelected,
        calendarsDictionary,
        timeUnits,
        canAccess,
        isUserJob,
        isMultiJobUser
    } = useSelector(selectGanttData);
    const {
        updatedDataActivityBroadcast
    } = useContext(PlanningContext);

    const tabPreferences = useSelector(selectTabPreferences);

    const [activityCustomFields, setActivityCustomFields] = useState([...planningCustomFields]);
    const [jobs, setJobs] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState(activity.jobId);
    const [champPmAutoOptions, setChampPmAutoOptions] = useState([]);
    const [champPMAutoSelectedId, setChampPMAutoSelectedId] = useState([]);
    const [form] = Form.useForm();
    // const dateWithHourFormat = 'DD/MM/YYYY HH:mm';
    // const dateFormat = 'DD/MM/YYYY';
    const currentActivityData = window.ganttInstance.getTask(activity.id)
    const ganttTask = {
        ...currentActivityData,
        startDate: moment(activity.startDate),
        endDate: moment(activity.endDate),
        duration: currentActivityData?.durationApi,
        quantity: currentActivityData.data_api.quantity,
        quantityUnit: currentActivityData.data_api.quantityUnit,
        yield: currentActivityData.data_api.yield,
        champPMAutoId: currentActivityData.data_api.champPMAutoId,
        progress: currentActivityData.progress * 100,
        parent: currentActivityData.data_api.activityParentId,
        id: currentActivityData.serverId,
    };

    const isProject = +activity.serverId === planningSelected.rootActivityId;

    // locked
    const [isLocked, setIsLocked] = useState(activity.locked);
    const isFolder = window.ganttInstance.getAllChildren(activity.id).length > 0;
    const { Option } = Select;
    const [roundDuration, setRoundDuration] = useState(activity.data_api.roundedDuration);

    useEffect(() => {
        const onActivityChange = async () => {
            setLoading(true);
            const activitiesList = Object.values(activitiesDictionary);
            setActivities(activitiesList.filter((act) => act.id !== activity.serverId));

            const calendarList = Object.values(calendarsDictionary);
            setCalendars(calendarList);
            // try {
            //     const timeUnitsList = await CalendarService.listTimeUnits();
            //     setTimeUnits(timeUnitsList);
            // } catch (error) {
            //     requestError(error, 'Erreur lors de la récupération des unités de temps');
            // }
                const autoUnifiedField = isFolder ? [DefaultUnifiedFieldId.quantity, DefaultUnifiedFieldId.yield] : [DefaultUnifiedFieldId.duration,DefaultUnifiedFieldId.quantity, DefaultUnifiedFieldId.yield];
                setChampPmAutoOptions(
                    allCustomFields.filter((customField) => autoUnifiedField.includes(customField.id))
                );
                setChampPMAutoSelectedId(activity.champPMAutoId);

            const jobsList = Object.keys(planningCustomFields.find((i) => i.name === JOB_FIELD)?.type.choices);

            setJobs(
                jobsList.map((i) => ({
                    value: i,
                    label: i === ALL_JOBS ? translate('general.all_jobs') : i,
                    disabled: isMultiJobUser ? false : isUserJob([i]),
                }))
            );

            setActivityCustomFields(planningCustomFields.map((customField) => ({ ...customField })));
            setLoading(false);
        };
        onActivityChange();
    }, [activity]);


    useEffect(() => {
        if (
            updatedDataActivityBroadcast && updatedDataActivityBroadcast.id === activity.serverId
        ) {
            const activityUpdated = updatedDataActivityBroadcast.updatedData;
            const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(activityUpdated.dayDefinitionId));
        const ganttTaskUpdated = {
            ...activityUpdated,
            startDate: moment(activityUpdated.startDate).utc(),
            endDate: moment(activityUpdated.endDate).utc(),
            yieldUnit: getYieldUnit(activityUpdated.quantityUnit, activityUpdated.dayDefinitionId),
            duration: Number(activityUpdated.duration / durationUnit.duration) 
        };
        
        console.log("🚀 ~ file: ActivityDetailsTab.jsx:152 ~ useEffect ~ ganttTaskUpdated:", ganttTaskUpdated)
        form.setFieldsValue(ganttTaskUpdated);
        }
    }, [updatedDataActivityBroadcast]);

    const deleteTask = async () => {
        if (saving) {
            return;
        }

        setSaving(true);
        try {
            setLoading(false);

            ganttTask.predecessorLinksId.forEach((linkId) => {
                const ganttLink = window.ganttInstance.getLink(linkId);

                if (ganttLink) {
                    ganttLink.is_virtual = true;
                }
            });
            ganttTask.successorLinksId.forEach((linkId) => {
                const ganttLink = window.ganttInstance.getLink(linkId);

                if (ganttLink) {
                    ganttLink.is_virtual = true;
                }
            });

            await ActivityService.deleteActivity(ganttTask.id);
            setSaving(false);
            window.ganttInstance.deleteTask(activity.id);
            window.ganttInstance.hideLightbox();
            // refreshPlanningSelected();
        } catch (error) {
            setLoading(false);
            if (error?.response?.status === 403) {
                notificationError(
                    t('error_delete_activity'),
                    t('validated_activity')
                );
            } else {
                requestError(error, t('error_delete_activity'));
            }
            Sentry.captureException(error);
        }
    };

    const updateCustomFieldValue = async (value, customFieldId) => {
        try {
            const newActivityCustomFields = activityCustomFields.reduce(
                (agg, customField) => Object.assign(agg, { [customField.id]: customField }),
                {}
            );
            newActivityCustomFields[customFieldId].value = value;
            setActivityCustomFields(Object.values(newActivityCustomFields));
            await CustomFieldService.updateCustomFieldActivityValue(customFieldId, ganttTask.id, [value]);
            // UPDATE PERFORMED BY NOTIFIER
            // update style and dictionnary
            // const activityUpdated = activity.data_api;
            // updateActivitiesDictionary(activityUpdated.id, activityUpdated);
            // const customField = planningCustomFields.find((cf) => cf.id === customFieldId);
            // const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(ganttTask.dayDefinitionId));
            // if (customField.type.choices[value]) {
            // window.ganttInstance.updateTask(
            //     activity.id,
            //     formatGanttTask(activityUpdated, {
            //         durationApi: Number(activityUpdated.duration / durationUnit.duration),
            //     })
            // );
            // const isColorFiltered = userPreferences.planning_color?.[planningSelected.id];
            // if (isColorFiltered && isColorFiltered.name !== JOB_FIELD) {
            //     setRefreshGantt((val) => val + 1);
            // }
            // }
            // } else {
            //     window.ganttInstance.updateTask(
            //         activity.id,
            //         formatGanttTask(activityUpdated, {
            //             durationApi: Number(activityUpdated.duration / durationUnit.duration),
            //         })
            //     );
            // }

            // window.ganttInstance.refreshData();
        } catch (error) {
            requestError(error, t('error_activity_update'));
        }
    };
    

    // get status after lock or unlock value in [lock, unlock]
    const getNewActivityStatus = (value, task) => {
        let statusValue = task.status;
        if (value === 'lock') {
            statusValue = ACTIVITY_STATE.LOCKED;
        }
        if (value === 'unlock') {
            statusValue = ACTIVITY_STATE.UNVALID;
        }
        return statusValue;
    };
    const updateChildTaskStatus = (taskIds, value) => {
        taskIds.forEach((taskId) => {
            const task = window.ganttInstance.getTaskByServerId(taskId);
            if (isUserJob(task?.jobId) || isMultiJobUser) {
                const newStatus = getNewActivityStatus(value, task);
                dispatch(updateActivitiesDictionary(taskId, {
                    activityState: newStatus,
                }));
                const taskData = window.ganttInstance.getTaskByServerId(taskId).data_api;
                taskData.activityState = newStatus;
                window.ganttInstance.updateTaskByServerId(taskId, formatGanttTask({ ...taskData }));
                if (task.children.length > 0) {
                    updateChildTaskStatus(task.children, value);
                }
            }
        });
    };

    const updateActivityData = async (attr, value) => {
        try {
            setLoading(true)
            let activityUpdated = { ...activity };
            const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(ganttTask.dayDefinitionId));
            switch (attr) {
                case 'identity':
                    activityUpdated = await ActivityService.updateActivityIdentity(ganttTask.id, value);
                    break;
                case 'text':
                    activityUpdated = await ActivityService.updateActivityName(ganttTask.id, value);
                    break;
                case 'description':
                    activityUpdated = await ActivityService.updateActivityDescription(ganttTask.id, value);
                    break;
                case 'job':
                    activityUpdated = { ...activity.data_api, job: value };
                    activityUpdated = await ActivityService.updateActivityJobId(ganttTask.id, value);
                    break;
                case 'parent': {
                    await ActivityService.updateActivityParent(value, [ganttTask.id.toString()]);
                    // deleting links from gantt
                    const parentTask = window.ganttInstance.getTaskByServerId(value);
                    const source = parentTask.$source;
                    // links that come from the task,

                    source.forEach((linkId) => {
                        const link = window.ganttInstance.getLink(linkId);
                        link.is_virtual = true;
                        window.ganttInstance.deleteLink(linkId);
                    });

                    const target = parentTask.$target;
                    // links that come into the task,

                    target.forEach((linkId) => {
                        const link = window.ganttInstance.getLink(linkId);
                        link.is_virtual = true;
                        window.ganttInstance.deleteLink(linkId);
                    });
                    activityUpdated = await ActivityService.showActivity(ganttTask.id);
                    break;
                }
                case 'quantity':
                    activityUpdated = await ActivityService.updateActivityQuantity(ganttTask.id, value);
                    break;
                case 'quantityUnit':
                    activityUpdated = await ActivityService.updateActivityQuantityUnit(ganttTask.id, value);
                    break;
                case 'yield':
                    activityUpdated = await ActivityService.updateActivityYield(ganttTask.id, value);
                    break;
                case 'champPMAutoId':
                    setChampPMAutoSelectedId(value);
                    activityUpdated = await ActivityService.updateActivityChampPm(ganttTask.id, value, roundDuration);
                    break;
                case 'roundedDuration':
                    setRoundDuration(value);
                    activityUpdated = await ActivityService.updateActivityChampPm(ganttTask.id, champPMAutoSelectedId, value);
                    break;
                case 'calendarId':
                    activityUpdated = await ActivityService.updateActivityCalendarId(ganttTask.id, value);
                    break;
                case 'startDate':
                    activityUpdated = await ActivityService.updateActivityStartDate(
                        ganttTask.id,
                        value.clone().utc(true).valueOf()
                    );
                    break;
                case 'endDate':
                    activityUpdated = await ActivityService.updateActivityEndDate(
                        ganttTask.id,
                        value.clone().utc(true).valueOf()
                    );
                    break;
                case 'dayDefinitionId':
                    activityUpdated = await ActivityService.updateActivityTimeUnit(ganttTask.id, value);
                    break;
                case 'duration': {
                    activityUpdated = await ActivityService.updateActivityDuration(ganttTask.id, Number(value));
                    break;
                }
                case 'progress':
                    activityUpdated = await ActivityService.updateActivityAvancement(ganttTask.id, Number(value));
                    break;
                case 'activity-state': {
                    const newStatus = getNewActivityStatus(value, activity);

                    activityUpdated = await ActivityService.updateActivityState(ganttTask.id, newStatus);
                    if (newStatus === ACTIVITY_STATE.LOCKED) {
                        computeCannotEdit(activity.serverId, newStatus, activity.jobId);
                        setIsLocked(true);
                        window.ganttInstance.unselectTask(activity.id);
                    } else {
                        computeCannotEdit(activity.serverId, newStatus, activity.jobId);
                        setIsLocked(false);
                    }
                    // updating childs status
                    if (activity.type === window.ganttInstance.config.types.customProject) {
                        updateChildTaskStatus(activity.children, value);
                    }
                    break;
                }
                default:
                    console.error(`${attr} Not implemented `);
            }
            const updatedData = {
                ...activityUpdated,
                startDate: moment(activityUpdated.startDate).utc(),
                endDate: moment(activityUpdated.endDate).utc(),
                yieldUnit: getYieldUnit(activityUpdated.quantityUnit, activityUpdated.dayDefinitionId),
            };
            setLoading(false)
            form.setFieldsValue({ ...updatedData, duration: Number(activityUpdated.duration / durationUnit.duration) });
            // window.ganttInstance.refreshData();
            // check if need to reget non working days
            // if isDateFiltered do not do reget
            await dispatch(refetchCalendars(activityUpdated));
            dispatch( incrementReRenderGantt())

        } catch (error) {
            requestError(error, t('error_update_activity'));
            Sentry.captureException(error);
        }
    };

    const onTaskProressChange = (val) => {
        setTaskProgress(val);
        updateActivityData('progress', val);
    };

    const renderCustomField = (customField) => {
        if (customField.type.text) {
            return (
                <Form.Item label={customField.name} name={`customField#${customField.id}`}>
                    <Input
                        value={customField.value}
                        onBlur={(event) => updateCustomFieldValue(event.target.value, customField.id)}
                        disabled={cannotEdit || isProject}
                    />
                </Form.Item>
            );
        }

        if (customField.type.numeric) {
            return (
                <Form.Item
                    label={customField.name}
                    name={`customField#${customField.id}`}
                    initialValue={customField.value}
                >
                    <InputNumber
                        onBlur={(event) => updateCustomFieldValue(event.target.value, customField.id)}
                        disabled={cannotEdit || isProject}
                        keyboard={false}
                    />
                </Form.Item>
            );
        }

        return (
            <Form.Item
                label={customField.name}
                style={{ width: '115px' }}
                name={`customField#${customField.id}`}
                initialValue={customField.value}
            >
                <Select
                    options={sortBy(Object.keys(customField.type.choices).reduce((options, customFieldChoiceName) => {
                        options.push({
                            label: customFieldChoiceName,
                            value: customFieldChoiceName,
                        });

                        return options;
                    }, []), opt => opt.label)}
                    onChange={(value) => updateCustomFieldValue(value, customField.id)}
                    disabled={cannotEdit || isProject}
                    allowClear
                />
            </Form.Item>
        );
    };

    const handleJobsChange = useCallback(
        (value) => {
            if (value.length === 0) {
                return;
            }
            let jobsToBeSelected = [];

            if (selectedJobs.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
                jobsToBeSelected = [ALL_JOBS];
            } else {
                jobsToBeSelected = [...value];
            }
            if (selectedJobs.indexOf(ALL_JOBS) > -1) {
                jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
            }
            setSelectedJobs(jobsToBeSelected);
            updateActivityData('job', jobsToBeSelected);
        },
        [selectedJobs]
    );

    return (
        <Spin spinning={loading}>

        <div className='h-full flex flex-col'>
            <DrawerRow className='overflow-y-auto overflow-x-hidden flex-grow'>
                <Form
                    form={form}
                    initialValues={ganttTask}
                    layout="horizontal"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    labelAlign="left"
                    name="task_form"
                    className="mt-3"
                    colon={false}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={fieldTranslate('id')} name="identity">
                                <Input
                                    onBlur={(event) => updateActivityData('identity', event.target.value)}
                                    disabled={cannotEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <DrawerRow>
                        <Form.Item label={fieldTranslate('parent')} name="parent">
                            <Select
                                showSearch
                                options={activities.reduce((agg, item) => {
                                    agg.push({ label: item.name, value: item.id });

                                    return agg;
                                }, [])}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => updateActivityData('parent', value)}
                                disabled={cannotEdit || isProject}
                            />
                        </Form.Item>
                    </DrawerRow>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={fieldTranslate('name')}
                                name="text"
                                required
                            >
                                <Input
                                    onBlur={(event) => updateActivityData('text', event.target.value)}
                                    disabled={cannotEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={fieldTranslate('description')} name="description">
                                <Input
                                    onBlur={(event) => updateActivityData('description', event.target.value)}
                                    disabled={cannotEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <DrawerRow>
                        <Form.Item
                            label={fieldTranslate('jobs')}
                            required
                        >
                            <Select
                                disabled={cannotEdit || isProject}
                                options={jobs}
                                value={selectedJobs}
                                mode="multiple"
                                onChange={(value) => handleJobsChange(value)}
                            />
                        </Form.Item>
                    </DrawerRow>

                    <h3>Dates</h3>
                    <DrawerRow>
                        <Form.Item
                            label={fieldTranslate('calendar')}
                            name="calendarId"
                            required
                        >
                            <Select
                                options={calendars.reduce((agg, calendar) => {
                                    agg.push({ label: calendar.name, value: calendar.id });

                                    return agg;
                                }, [])}
                                onChange={(value) => updateActivityData('calendarId', value)}
                                disabled={cannotEdit}
                            />
                        </Form.Item>
                    </DrawerRow>
                    <DrawerRow>
                        <div className="ant-form ant-form-vertical" style={{ display: 'flex' }}>
                            <Form.Item
                                label={fieldTranslate('start_date')}
                                name="startDate"
                                className="pr-1 w-1/2"
                                labelCol={12}
                                wrapperCol={12}
                            >
                                <DatePicker
                                    className="w-full"
                                    format={
                                        tabPreferences?.gantt_parameters?.hideHours ||
                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                        ? window.dateStringFormat
                                        : window.dateStringFormat.concat(' HH:mm')
                                    }
                                    allowClear={false}
                                    showTime={
                                        tabPreferences?.gantt_parameters?.hideHours ||
                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                            ? false
                                            : {
                                                  defaultValue: moment('00:00:00', 'HH:mm:ss'),
                                                  format: 'HH:mm',
                                              }
                                    }
                                    onSelect={(value) => value && updateActivityData('startDate', value)}
                                    onChange={(value) => value && updateActivityData('startDate', value)}
                                    disabled={cannotEdit || isFolder}
                                />
                            </Form.Item>
                            <Form.Item label={fieldTranslate('end_date')} name="endDate" className="pl-1 w-1/2" labelCol={12} wrapperCol={12}>
                                <DatePicker
                                    className="w-full"
                                    allowClear={false}
                                    format={
                                        tabPreferences?.gantt_parameters?.hideHours ||
                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                        ? window.dateStringFormat
                                        : window.dateStringFormat.concat(' HH:mm')
                                    }
                                    showTime={
                                        tabPreferences?.gantt_parameters?.hideHours ||
                                        tabPreferences?.gantt_parameters?.hideHours === undefined
                                            ? false
                                            : {
                                                  defaultValue: moment('23:59:59', 'HH:mm:ss'),
                                                  format: 'HH:mm',
                                              }
                                    }
                                    onSelect={(value) => value && updateActivityData('endDate', value)}
                                    onChange={(value) => value && updateActivityData('endDate', value)}
                                    disabled={cannotEdit || isFolder}
                                />
                            </Form.Item>
                        </div>
                    </DrawerRow>
                    <DrawerRow>
                        <Form.Item label={fieldTranslate('duration')} style={{ marginBottom: 0 }} labelCol={3} wrapperCol={21}>
                            <Form.Item
                                name="duration"
                                style={{ display: 'inline-block', width: 'calc(42% - 3px)', marginRight: '8px' }}
                            >
                                <InputNumber
                                    precision={(champPMAutoSelectedId === DefaultUnifiedFieldId.duration && roundDuration) ? 0 : 1}
                                    onBlur={(event) => updateActivityData('duration', event.target.value)}
                                    disabled={
                                        cannotEdit ||
                                        isFolder ||
                                        champPMAutoSelectedId === DefaultUnifiedFieldId.duration
                                    }
                                    min={0}
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item
                                name="dayDefinitionId"
                                style={{ display: 'inline-block', width: 'calc(57% - 3px)' }}
                            >
                                <Select
                                    options={timeUnits.reduce((agg, timeUnit) => {
                                        agg.push({ label: timeUnit.name, value: timeUnit.id });

                                        return agg;
                                    }, [])}
                                    onChange={(value) => updateActivityData('dayDefinitionId', value)}
                                    disabled={cannotEdit}
                                />
                            </Form.Item>
                        </Form.Item>
                    </DrawerRow>
                    <DrawerRow>
                        <Form.Item
                            name="progress"
                            label={fieldTranslate('progress')}
                            style={{ display: 'inline-block', width: 'calc(42% - 3px)' }}
                            labelCol={{ span: 7 }}
                            wrapperCol={17}
                        >
                            <Row>
                                <Col span={19}>
                                    <Slider
                                        disabled={cannotEdit}
                                        min={0}
                                        max={100}
                                        onAfterChange={onTaskProressChange}
                                        defaultValue={typeof taskProgress === 'number' ? taskProgress : 0}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        disabled={cannotEdit}
                                        min={0}
                                        max={100}
                                        precision="0"
                                        formatter={(value) => Number(value).toFixed(0)}
                                        value={taskProgress}
                                        onChange={onTaskProressChange}
                                        type="number"
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </DrawerRow>

                    <Collapse ghost className='activity-modification'>
                        <Panel header={t('calcul_params')} key="1">
                            <DrawerRow>
                                <Form.Item label={t('round_duration')} labelCol={{ span: 17 }}
                                        wrapperCol={7}>
                                <Checkbox
                                    checked={roundDuration}
                                    disabled={cannotEdit || isProject}
                                    onChange={(e) => updateActivityData('roundedDuration', e.target.checked)}
                                />
                                </Form.Item>
                                
                            </DrawerRow>
                            <DrawerRow>
                                <Form.Item label={fieldTranslate('calcul')} name="champPMAutoId">
                                    <Select
                                        showSearch
                                        options={champPmAutoOptions.reduce((agg, item) => {
                                            agg.push({ label: item.name, value: item.id });
                                            return agg;
                                        }, [])}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) =>
                                            value
                                                ? updateActivityData('champPMAutoId', value)
                                                : updateActivityData('champPMAutoId', 0)
                                        }
                                        allowClear
                                        disabled={cannotEdit || isProject}
                                    >
                                        <Option value="null">{t('no_calculation')}</Option>
                                    </Select>
                                </Form.Item>
                            </DrawerRow>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label={fieldTranslate('quantity')}
                                        style={{ marginBottom: 0 }}
                                        labelCol={{ span: 7 }}
                                        wrapperCol={17}
                                    >
                                        <Form.Item
                                            name="quantity"
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                marginRight: '8px',
                                            }}
                                        >
                                            <InputNumber
                                                onBlur={(event) => updateActivityData('quantity', event.target.value)}
                                                disabled={
                                                    cannotEdit ||
                                                    isProject ||
                                                    champPMAutoSelectedId === DefaultUnifiedFieldId.quantity
                                                }
                                                min={0}
                                                type="number"
                                                controls={false}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="quantityUnit"
                                            style={{ display: 'inline-block', width: '50%' }}
                                        >
                                            <Select
                                                options={quantityUnits}
                                                onChange={(value) => updateActivityData('quantityUnit', value)}
                                                disabled={cannotEdit || isProject}
                                            />
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label={fieldTranslate('yield')}
                                        style={{ marginBottom: 0 }}
                                        labelCol={{ span: 7 }}
                                        wrapperCol={17}
                                    >
                                        <Form.Item
                                            name="yield"
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(50% - 8px)',
                                                marginRight: '8px',
                                            }}
                                        >
                                            <InputNumber
                                                onBlur={(event) => updateActivityData('yield', event.target.value)}
                                                disabled={
                                                    cannotEdit ||
                                                    isProject ||
                                                    champPMAutoSelectedId === DefaultUnifiedFieldId.yield
                                                }
                                                min={0}
                                                type="number"
                                                controls={false}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="yieldUnit"
                                            style={{ display: 'inline-block', width: '50%' }}
                                            initialValue={getYieldUnit(
                                                form.getFieldValue('quantityUnit'),
                                                form.getFieldValue('dayDefinitionId')
                                            )}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <h3>{t('custom_fields')}</h3>
                    {activityCustomFields
                        .filter((customField) => customField.name !== JOB_FIELD)
                        .map((customField) => (
                            <DrawerRow key={`activity-${activity.id}-custom-field-${customField.id}`}>
                                {renderCustomField(customField)}
                            </DrawerRow>
                        ))}
                    {activityCustomFields.length < 2 && (
                        <h5>
                            <br />
                            {t('no_custom_fields')}
                        </h5>
                    )}
                </Form>
            </DrawerRow>
            <div
                className="w-full pt-2 mb-2 bg-white text-right flex justify-between" 
                style={{ height: '40px', borderTop: '1px solid #e9e9e9' }}
            >
                {isLocked ? (
                    <Button
                        type="primary"
                        icon={<UnlockOutlined />}
                        className="flex items-center"
                        onClick={() => updateActivityData('activity-state', 'unlock')}
                        disabled={!canAccess}
                    >
                        {t('unlock_activity')}
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        icon={<LockOutlined />}
                        className="flex items-center"
                        onClick={() => updateActivityData('activity-state', 'lock')}
                        disabled={cannotEdit}
                    >
                        {t('lock_activity')}
                    </Button>
                )}
                {!isLocked && (
                    <Popconfirm
                        title={
                            isFolder
                                ? t('delete_parent_task_text')
                                : t('delete_normal_task_text')
                        }
                        onConfirm={deleteTask}
                        onCancel={() => {}}
                        okText={translate('general.yes')}
                        cancelText={translate('general.no')}
                    >
                        <Button 
                            danger
                            type="primary"
                            icon={<DeleteOutlined />}
                            className="flex items-center"
                            disabled={saving || cannotEdit}
                        >
                            {translate('general.delete')}
                        </Button>
                    </Popconfirm>
                )}
            </div>
        </div>
        </Spin>
    );
};

ActivityDetailsTab.propTypes = {
    activity: PropTypes.object.isRequired,
    cannotEdit: PropTypes.bool.isRequired,
    computeCannotEdit: PropTypes.func.isRequired
};

export default ActivityDetailsTab;
