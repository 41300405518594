import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import SubmitButton from '../../components/common/forms/SubmitButton';
import AuthService from '../../services/auth.service';
import { setTokenInfo } from '../../helpers/localStorage.-helper';
import { notificationError } from '../../helpers/notification';


const Login = ({ history }) => {
    const emailRef = useRef(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (AuthService.getAccessToken()) {
            history.push('/programs');
        }
        // TOKEN MANAGEMENT
        const urlParams = new URLSearchParams(window.location.search);

        // Get a specific parameter value
        const refreshToken = urlParams.get('t');
        if (refreshToken) {
            // we get new token info, and login automatically
            // if request fails, fallback to normal login process
            axios.post(`${window._env_.REACT_APP_WALLET_API}/sessions/refresh`, { refreshToken }).then((response) => {
                if (response.status === 200) {
                    setTokenInfo(response.data)
                    window.location.reload();
                } else {
                    notificationError(t('login.connexion'), t('login.automatic_login_failed'))
                }
            }).catch(() => {
                notificationError(t('login.connexion'), t('login.automatic_login_failed'))
            });
        }
        emailRef.current.focus({
            cursor: 'start',
        });
    }, []);

    const [form] = Form.useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loginError = () => {
        setIsLoading(false);
        form.setFields([
            {
                name: 'password',
                errors: ['Adresse email ou mot de passe incorrect'],
            },
        ]);
    };

    const submitForm = async (values) => {
        setIsLoading(true);

        try {
            await AuthService.login(values.email, values.password);
            await AuthService.getSession();
            setIsLoading(false);
            // history.push('/programs');
            const targetUrl = localStorage.getItem('targetUrl');
            if (targetUrl) {
                localStorage.removeItem('targetUrl');
                window.location.href = targetUrl;
            } else {
                window.location.reload();
            }
        } catch (error) {
            loginError();
        }
    };

    const validationRules = useMemo(() => {
        const result = {
            email: [
                {
                    required: true,
                    message: i18n.t('login.mail_required'),
                },
                {
                    type: 'email',
                    message: i18n.t('login.mail_invalid'),
                },
            ],
            password: [
                {
                    required: true,
                    message: i18n.t('login.password_required'),
                },
            ],
        };
        return result;
    }, [i18n.language]);

    return (
        <>
            <h1>{t('login.welcome')}</h1>
            <p className="form-description">{t('login.welcome_text')}</p>

            <Form
                className="mt-9 mx-auto login-form"
                name="login_form"
                layout="vertical"
                form={form}
                initialValues={{ email: '', password: '' }}
                requiredMark={false}
                validateTrigger="onBlur"
                onFinish={submitForm}
            >
                <Form.Item label={t('login.mail_text')} name="email" rules={validationRules?.email}>
                    <Input placeholder={t('login.mail_text')} ref={emailRef} />
                </Form.Item>
                <div className="relative">
                    <Form.Item label={t('login.password_text')} name="password" rules={validationRules?.password}>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('login.password_text')}
                            className="password-input"
                        />
                    </Form.Item>
                    <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="absolute top-9 right-4 cursor-pointer text-gray-400"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </div>
                <Form.Item className="pt-4">
                    <SubmitButton loading={isLoading}>{t('login.connexion')}</SubmitButton>
                    <p className="text-center text-sm text-gray-400 italic mt-2">
                        <Link to="/mot-de-passe-oublie">{t('login.forgot_password')}</Link>
                    </p>
                </Form.Item>
            </Form>
        </>
    );
};

Login.propTypes = {
    history: PropTypes.any.isRequired,
};

export default Login;
