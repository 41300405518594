import moment from 'moment';
// import { mergeNeedSlices, sliceJoinedSimple } from 'slicing-gm';
/* eslint-disable*/
import {
    generateActivityTemporalNeedSliceFromActivityCalendarSlice,
    generatorTimestamp,
    // generatorTimestamp,
} from '../../helpers/slicing-helper';
import { dateToTimestamp } from '../../helpers/planning';
import { xAxisDateFormat } from '../../components/charts/CustomHistogramDateXaxis';
import { selectActivityEntities } from '../slices/app/activity.slice';
import { selectPlanningSelected } from '../slices/app/planning.slice';
import { selectActivityCalendarSlice, selectScale } from '../slices/slicing/activity-calendar.slice';
import { selectTimeUnits } from '../slices/app/calendar.slice';
import createAppAsyncThunk from './create-typed-async-thunk';
import { mergeNeedSlices, sliceJoinedSimple } from 'slicing-gm';
import {
    DEFAULT_CHART_OPTIONS,
    selectChartOptions,
    setChartData,
    setChartOptions,
    setNeedList,
    setShouldUpdateChartData,
} from '../slices/chart/need-chart.slice';
import { selectTabPreferences, updateTabPreferences } from '../slices/app/tab.slice';
import { getArrayInListByCondition } from '../../helpers/tree-helper';
import { NEED_TYPE } from '../../constants/Needs';
import { translateWithPrefix } from '../../i18n';
/* eslint-disable-next-line */
import Worker from '../../workers/chart-data.worker';


export const computeActivityCalendarTemporalSliceDictionary = createAppAsyncThunk(
    'chart/computeActivityCalendarTemporalSliceDictionary',
    async (_, { getState }) => {
        const generalTranslation = translateWithPrefix('general');
        const state = getState();
        const scale = selectScale(state);
        const activityCalendarSliceDictionary = selectActivityCalendarSlice(state);
        const { chartOptions } = state.needChart;
        const activitiesDictionary = selectActivityEntities(state);
        const timeUnits = selectTimeUnits(state);

        const activityCalendarTemporalNeedSlices = {};
        const temporalityUnit = xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
        Object.values(activitiesDictionary).forEach((activity: any) => {
            if (activityCalendarSliceDictionary[activity.id]?.length > 0) {
                const currentActivityCalendarTemporalNeedSlices =
                    generateActivityTemporalNeedSliceFromActivityCalendarSlice(
                        activityCalendarSliceDictionary[activity.id],
                        temporalityUnit,
                        activity,
                        (dayDefinitionId) => timeUnits.find((timeUnit) => timeUnit.id === Number(dayDefinitionId)),
                        chartOptions
                    );

                activityCalendarTemporalNeedSlices[activity.id] = currentActivityCalendarTemporalNeedSlices;
            }
        });

        return activityCalendarTemporalNeedSlices;
    }
);


export const computeChartData = createAppAsyncThunk(
  'chart/computeChartData',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const {
      activityCalendarTemporalNeedSliceDictionary,
      needList,
      chartOptions
    } = state.needChart;
      console.log("🚀 ~ activityCalendarTemporalNeedSliceDictionary:", activityCalendarTemporalNeedSliceDictionary)
    const scale = selectScale(state);
    const planningSelected = selectPlanningSelected(state);
    const activitiesDictionary = selectActivityEntities(state);
    const generalTranslation = translateWithPrefix('general');


    const timeUnit = xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
    const rootActivity = activitiesDictionary[planningSelected.rootActivityId];
    const startTime = (window as any).ganttInstance
        ? dateToTimestamp((window as any).ganttInstance.getState().min_date)
        : rootActivity.startDate;
    const endTime = (window as any).ganttInstance
        ? dateToTimestamp(
              (window as any).ganttInstance.date.add(
                  (window as any).ganttInstance.getState().max_date,
                  0,
                  timeUnit.replace('s', '')
              )
          )
        : moment(rootActivity.endDate).utc().add(2, timeUnit).valueOf();
    
    
    const ganttData = Object.values(activitiesDictionary);
    const worker = new Worker();
    const timeStart = Date.now();

    worker.onmessage = (event) => {
      dispatch(setChartData(event.data));
      console.log('processing finished after', (Date.now() - timeStart)/1000, 's');
      worker.terminate(); // Terminate the worker after receiving the result
    };

    worker.onerror = (error) => {
      console.error('Worker error:', error);
      // reject(error);
      worker.terminate(); // Terminate the worker in case of an error
    };
    worker.postMessage({
      type: 'COMPUTE_CHART_DATA',
      activityCalendarTemporalNeedSliceDictionary,
      needList,
      chartOptions,
      startTime,
      endTime,
      ganttData,
      timeUnit
    });
    return 'computeChartData done'
    // return new Promise((resolve, reject) => {
    //   console.log('INIT WORKER')
    //   const worker = new Worker();

    //   worker.onmessage = (event) => {
    //     console.log("🚀 ~ returnnewPromise ~ event:", event)
    //     // dispatch(setChartData(event.data));
    //     worker.terminate(); // Terminate the worker after receiving the result
    //   };

    //   worker.onerror = (error) => {
    //     console.error('Worker error:', error);
    //     // reject(error);
    //     worker.terminate(); // Terminate the worker in case of an error
    //   };

      
    // });
  }
);
export const updateChartOptions = createAppAsyncThunk(
    'chart/updateChartOptions',
    async (newOptions: Partial<typeof DEFAULT_CHART_OPTIONS>, { dispatch, getState }) => {
        const state = getState();
        const currentOptions = state.needChart.chartOptions;
        const updatedOptions = { ...currentOptions, ...newOptions };

        dispatch(setChartOptions(updatedOptions));
        dispatch(
            updateTabPreferences({
                gantt_histogram_options: updatedOptions,
            })
        );

        if (newOptions.needType || newOptions.dataType || newOptions.sliceDataType) {
            dispatch(computeChartData());
        }
    }
);

export const updateNeedList = createAppAsyncThunk('chart/updateNeedList', async (_, { dispatch, getState }) => {
    const state = getState();
    const chartOptions = selectChartOptions(state);
    const { globalNeedList, planningNeedList } = state.needs;
    const tabPreferences = selectTabPreferences(state);
    dispatch(setShouldUpdateChartData(true));
    const needs = (
        chartOptions.needType === NEED_TYPE.NON_CONSUMMABLE
            ? [
                  ...getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
                  ...getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.NON_CONSUMMABLE),
              ]
            : [
                  ...getArrayInListByCondition(globalNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
                  ...getArrayInListByCondition(planningNeedList, 'needType', NEED_TYPE.CONSUMMABLE),
              ]
    ).map((need) => ({
        ...need,
        selected: tabPreferences.gantt_histogram_needs?.[chartOptions?.needType]
            ? tabPreferences.gantt_histogram_needs[chartOptions?.needType].indexOf(need.id) !== -1
            : true,
    }));

    dispatch(setNeedList(needs));
});

export default computeChartData;
