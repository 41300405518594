import React from 'react';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateString } from '../../helpers/string-helper';

const ImportValidationList = ({ validationData, onCellClick }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_import' });

    return (
        <div className="w-1/4 ml-1 overflow-auto">
            <List
                size="small"
                header={<label>{t('verification_result')}</label>}
                bordered
                dataSource={validationData}
                renderItem={(item) => (
                    <List.Item
                        key={generateString(7)}
                        className="hover:text-primary cursor-pointer"
                        onClick={() => onCellClick(item.cellId)}
                    >
                        {item.message}
                        {item.extra?.possibleValues.length > 0 && (
                            <p>{t('possible_values')}: {item.extra.possibleValues.join(', ')}</p>
                        )}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ImportValidationList; 