import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import {Button, Row, Table, Input, Breadcrumb, Popconfirm, Badge} from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Types from './Types';
// import '../../assets/styles/wallet/ProgramList.css'
import WalletService from '../../services/wallet.service';
import EditProgramModal from './Program/EditProgramModal';
import { requestError } from '../../helpers/notification';
import { WalletContext } from '../../contexts/wallet/WalletContext';
import { searchData } from '../../helpers/array-filter';
import userService from '../../services/user.service';
import folderService from '../../services/folder.service';
import authService from '../../services/auth.service';
import {VersionContext } from "../../contexts/wallet/VersionContext";
import { selectCurrentUser } from '../../redux/slices/app/user.slice';

const { Search } = Input;
const TableContainer = styled.div`
    background: rgba(255, 255, 255, 0.85);
    border-radius: 2px;
    ovrerflow: auto;
    .ant-table {
        background: transparent;
        border-radius: 0px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > th {
        padding: 0.5rem;
    }
    .ant-table-pagination.ant-pagination {
        margin: 16px;
    }
`;
const ProgramList = () => {
    const history = useHistory();
    const {
        programList,
        setSelectedProgram,
        tableType,
        setTableType,
        loadingData,
        setLoadingData,
        refreshProgramList,
    } = useContext(WalletContext);
    const currentUser = useSelector(selectCurrentUser)
    // State for program's list
    const [listPrograms, setListPrograms] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    // State for type's filter
    const [activeType, setActiveType] = useState('all');
    // const [loadingTable, setLoadingTable] = useState(false);

    const type = listPrograms.reduce((acc, elem) => (acc.includes(elem.type) ? acc : acc.concat(elem.type)), []);

    const editProgramModal = useRef(null);

    const { t } = useTranslation();

    const { dataProgramServices} = useContext(VersionContext);



    useEffect(() => {
        const programInfo = JSON.parse(localStorage.getItem('prg'));
        if (programInfo) {
            localStorage.removeItem('prg');
            window.location.reload();
        }
    }, []);

    // Informations of a program when we clicked on the button "Voir les informations du programme"
    /* const listInfoProgram = (programId) => {
        WalletService.showInfoProgram(programId)
            .then((data) => {
                Modal.info({
                    title: t('program_list.infos_about_project'),
                    content: (
                        <div key={data.id}>
                            <p>{t('program_list.current_project')} : {data.currentProject} </p>
                            <p>{t('program_list.job')} : {data.job} </p>
                            <p>{t('program_list.last_connection')} connexion : {data.lastConnection} </p>
                            <p>{t('program_list.teams')} : {data.teams} </p>
                        </div>
                    ),
                    onOk() {},
                });
            })
            .catch((error) => requestError(error, t('error_message.retrieving_program_infos_error')));
    }; */

    /** Recherche si un tag par defaut est indique dans la description du program */
    
    const findFrontProgramTag = (program) => {
        const {description} = program
        if(description){
            const searchVersion = /front="([^"]*)"/;
            const defaultVersion = description.match(searchVersion);
            return defaultVersion ? defaultVersion[1] : undefined;
        }
        return undefined;
    }

    const getAllLocalStorage = () => {
        const localStorageKeys = Object.keys(localStorage);
        const localStorageData = {};
      
        localStorageKeys.forEach(key => {
          localStorageData[key] = localStorage.getItem(key);
        });
      
        return localStorageData;
      }
      

    const frontRedirection = (reccord) => {
        const frontTag = findFrontProgramTag(reccord);
        const isFrontTagged = !!frontTag;
        const isDevMode = window.location.host === "localhost:3000";
        // Regexp match url of all ganttmanager adresses
        // ^(http(?>s)?:\/\/).*((?>stage\.)?ganttmanager\.(?>fr|com))$
        const regexp = '^(http(?:s)?://)(?:(?!\\bstage\\b).)*((?:stage.ganttmanager|ganttmanager).(?:fr|com))$';
        const locationRegexp = window.location.origin.match(regexp);
        console.log("🚀 ~ file: ProgramList.jsx:112 ~ frontRedirection ~ isGoodFront:", locationRegexp, window.location.origin)
        if(isFrontTagged && !isDevMode && locationRegexp.length != null){
            const isRightFront = locationRegexp[0].includes(frontTag);
            // no need to pass token 
            if (isRightFront) return false;
            const allLocalStorageData = getAllLocalStorage();
            const URLParamString = `?t=${allLocalStorageData.refreshToken}` 
            window.location.replace(`${locationRegexp[1]}${frontTag}.${locationRegexp[2]}/login${URLParamString}`);
            return true;
        }
        return false;
    }



    const setProgramUrl = (reccord) => {
        const { name, id, url } = reccord;
        localStorage.setItem('prg', JSON.stringify({ name, id, url }));
        if(!frontRedirection(reccord)){
            history.push(`/planning`);
            window.location.reload();
        }
    };

    const accessToProgram = (record) => {
        // userService
        //     .getProgramInfoByURL(record.url)
        //     .then((data) => {
                // if (data.userId && data.enable) {
                    setProgramUrl(record);
                // } else {
                //     notificationError(t('notification.access_to_program'), t('notification.you_cannot_access_this_program'));
                // }
            // })
            // .catch((error) => {
            //     console.log("🚀 ~ file: ProgramList.jsx:112 ~ accessToProgram ~ error:", error)
            //     notificationError(t('notification.access_to_program'), t('notification.you_cannot_access_this_program'));
            // });
    };

    const editProgram = (program) => {
        console.log('editProgram');
        setSelectedProgram(program);
        editProgramModal.current.openModal();
    };
    const deleteProgram = (programId) => {
        WalletService.deleteProgram(programId)
            .then(() => {
                refreshProgramList('delete', { id: programId });
            })
            .catch((error) => {
                requestError(error, t('error_message.error_while_deleting'));
            });
    };

    const goToFavoritePlanning = async ({ name, id, url, planningId }) => {
        try {
            setLoadingData(true);
            const planning = await folderService.showPlanningByUrl(url, planningId);
            const planningFolder = await folderService.showFolderByUrl(url, planning.folderId);
            const userPreferences = await authService.getPreferences(url);
            const modeSelected = 'live';
            const newPreferences = Object.assign(userPreferences, {
                open_folder: planningFolder,
                open_planning: { ...userPreferences.open_planning, [modeSelected]: planning },
            });
            await authService.updateUserPreferences(newPreferences, url);
            await userService.updateCurrentPlanning(planning.id, planning.name, url);
            setProgramUrl({ name, id, url });
            
        } catch (error) {
            t('planning_context.error_load_planning')
        }
    };

    const columnsProgram = [
        {
            title: '',
            dataIndex: 'logo',
            width: '10%',
            render: (logo) => <img style={{ height: 'auto', width: 'auto', maxHeight: '5rem', maxWidth : '5rem' }} src={logo} alt="" />,
        },
        {
            title: t('program_info.company'),
            dataIndex: 'company',
            width: '10%',
        },
        {
            title: t('program_info.name'),
            dataIndex: 'name',
            align: 'left',
            width: '10%',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: t('program_info.domain_area'),
            dataIndex: 'domainArea',
            width: '10%',
        },
        {
            title: t('program_info.description'),
            dataIndex: 'description',
            width: '30%',
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (d, record) => (
                <Row className="justify-center items-center">
                    {/* <Button className="m-3" onClick={() => listInfoProgram(record.id)} type="primary">
                        Voir les informations du programme
                    </Button> */}
                    <Button type="primary" className="mr-3" onClick={ () => accessToProgram(record) }>
                        {t('program_list.access_the_program')}
                    </Button>
                    {currentUser?.isPlAdmin && (
                        <>
                            <Button className="mr-3" type="primary" onClick={() => editProgram(record)}>
                                {t('general.edit')}
                            </Button>
                            <Popconfirm
                                title={t('notification.delete_program_confirmation')}
                                onConfirm={() => deleteProgram(record.id)}
                                okText={t('general.yes')}
                                cancelText={t('general.no')}
                            >
                                <Button type="primary">{t('general.delete')}</Button>
                            </Popconfirm>

                            <Badge count={dataProgramServices[record.name] ?  dataProgramServices[record.name].counter : -1} showZero
                                   color={dataProgramServices[record.name] && dataProgramServices[record.name].counter === Object.keys(dataProgramServices[record.name].services).length ? "#52c41a" : "#f5222d"} />
                        </>
                    )}
                </Row>
            ),
        },
    ];

    const columnsRecent = [
        {
            title: '',
            dataIndex: 'logo',
            render: (logo) => <img style={{ height: 'auto', width: 'auto', maxHeight: '5rem', maxWidth : '5rem' }} src={logo} alt="" />,

        },
        {
            title: t('recent_tab.program'),
            dataIndex: 'name',
            align: 'left',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: t('recent_tab.last_planning'),
            dataIndex: 'lastPlanning',
            align: 'left',
        },
        {
            title: t('recent_tab.last_access'),
            dataIndex: 'lastConnection',
            render: (lastConnection) =>
                lastConnection ? moment.utc(lastConnection).local().format('DD/MM/YYYY à HH:mm') : '',
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (d, record) => (
                <Row className="justify-center items-center">
                    <Button type="primary" className="mr-3" onClick={() => setProgramUrl(record)}>
                        {t('program_list.access_the_planning')}
                    </Button>
                </Row>
            ),
        },
    ];
    const columnsFavori = [
        {
            title: '',
            dataIndex: 'logo',
            render: (logo) => <img style={{ height: 'auto', width: 'auto', maxHeight: '5rem', maxWidth : '5rem' }} src={logo} alt="" />,
        },
        {
            title: t('favorite_tab.program'),
            dataIndex: 'name',
            align: 'left',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: t('favorite_tab.planning'),
            dataIndex: 'planningName',
            align: 'left',
        },
        // {
        //     title: 'Dernier accès',
        //     dataIndex: 'lastConnectionTime',
        //     render: (lastConnectionTime) => moment(lastConnectionTime).format('DD/MM/YYYY à HH:mm')
        // },
        {
            title: '',
            dataIndex: 'operation',
            // get planning info, update preference
            render: (d, record) => (
                <Row className="justify-center items-center">
                    <Button type="primary" className="mr-3" onClick={() => goToFavoritePlanning(record)}>
                        {t('program_list.access_the_planning')}
                    </Button>
                </Row>
            ),
        },
    ];

    const toogleTableColumns = () => {
        let columns = [];
        switch (tableType) {
            case 'Program':
                columns = columnsProgram;
                break;
            case 'Recent':
                columns = columnsRecent;
                break;
            case 'Favorite':
                columns = columnsFavori;
                break;

            default:
                columns = columnsProgram;
                break;
        }
        return columns;
    };
    useEffect(() => {
        const columns = toogleTableColumns();
        setTableColumns(columns);
        if (tableType === 'Program') {
            setListPrograms([...programList].sort((a, b) => a.name.localeCompare(b.name)));
        }
        if (tableType === 'Recent') {
            // mapping of array
            const recentPlannings = programList
                .filter((i) => i.info?.currentPlanning)
                .map((i) => ({
                    ...i,
                    lastPlanning: i.info?.currentPlanning?.planningName,
                    lastConnection: i.info?.currentPlanning?.connectionTime,
                }))
                .sort((a, b) => {
                    // First sort by lastConnection
                    const dateSort = new Date(b.lastConnection) - new Date(a.lastConnection);
                    // If dates are equal, sort by name
                    return dateSort === 0 ? a.name.localeCompare(b.name) : dateSort;
                });
            setListPrograms(recentPlannings);
        }
        if (tableType === 'Favorite') {
            // mapping of array
            const favoritePlannings = [];
            const programsWithInfo = programList.filter((i) => i.info);
            programsWithInfo.forEach((prg) => {
                (prg.info.favoritePlanningList || []).forEach((fav) => {
                    if (fav) {
                        favoritePlannings.push({ ...prg, planningName: fav.planningName, planningId: fav.planningId });
                    }
                });
            });
            setListPrograms(favoritePlannings.sort((a, b) => a.name.localeCompare(b.name)));
        }
    }, [tableType, currentUser, programList, dataProgramServices]);
    const handleSearch = (e) => {
        const filteredResult = searchData(
            programList.map((i) => ({ ...i, logo: null, description: null })),
            e.target.value
        );
        // Restore logos after search by mapping back to original program data
        const resultsWithLogos = filteredResult.map(result => {
            const originalProgram = programList.find(p => p.id === result.id);
            return {
                ...result,
                logo: originalProgram?.logo,
                description: originalProgram?.description
            };
        });
        setListPrograms(resultsWithLogos);
    };



    useEffect(() => {
        if (activeType !== 'all') {
            const newData = programList.filter((el) => el.domainArea === activeType);
            setListPrograms(newData);
        } else {
            setListPrograms([...programList]);
        }
        console.log('')
    }, [activeType]);

    useEffect(() => {
      console.log('listPrograms', listPrograms)
    }, [listPrograms])
    

    return (
        <div className="flex p-12 flex-col" style={{ height: 'calc(100vh - 5.5rem)', background: 'rgba(0,0,0,0.4)' }}>
            <Breadcrumb className="mb-2">
                <Breadcrumb.Item className="cursor-pointer" onClick={() => setTableType('Program')}>
                    <span className="text-lg w-full text-white">
                        <label className="text-primary" style={{ fontSize: '1.3rem' }}>
                            P
                        </label>
                        {t('general.programs')}
                    </span>
                </Breadcrumb.Item>
                {tableType === 'Recent' && (
                    <Breadcrumb.Item>
                        <span className="text-lg">{t('recent_tab.title')}</span>
                    </Breadcrumb.Item>
                )}
                {tableType === 'Favorite' && (
                    <Breadcrumb.Item>
                        <span className="text-lg">{t('favorite_tab.title')}</span>
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>

            <div className="w-full">
                <div className="flex mb-5 w-full justify-between">
                    <div className="flex">
                        <Search
                            placeholder={t('general.search')}
                            onChange={handleSearch}
                            allowClear
                            style={{ width: 400 }}
                            className="mr-12"
                        />
                        {tableType === 'Program' && (
                            <Types type={type} setActiveType={setActiveType} activeType={activeType} />
                        )}
                    </div>
                    {currentUser?.isPlAdmin && tableType === 'Program' && (
                        <Button onClick={() => editProgram(null)}>+ {t('program_list.new_program')}</Button>
                    )}
                </div>
                <TableContainer className="h-fit">
                    <Table
                        size='middle'
                        columns={tableColumns}
                        dataSource={listPrograms}
                        loading={loadingData}
                        pagination={{ pageSize: 10 }}
                        defaultSortOrder="ascend"
                        sortDirections={['ascend', 'descend']}
                    />
                </TableContainer>
            </div>
            <EditProgramModal key="modal-program" ref={editProgramModal} />
        </div>
    );
};
export default ProgramList;
