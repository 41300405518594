import React, { useRef } from 'react';
// import { useDraggable } from 'react-use-draggable-scroll';
import { useSelector } from 'react-redux';
import GanttHistogramChart from './GanttHistogramChart';
import YAxisDrawer from './YAxisDrawer';
import ChartOptions from './ChartOptions';
import { selectProcessingGantt } from '../../redux/slices/app/planning.slice';
/* eslint-disable */
const GanttViewChartContainer = () => {
    const processingGantt = useSelector(selectProcessingGantt);
    const ref = useRef(null);
    
    // const { events } = useDraggable(ref, {
    //     applyRubberBandEffect: true,
    //     isMounted: true,
    //     safeDisplacement: 10,
    // });

    return processingGantt === false && (
        <div style={{background: 'white', width: '100%'}} className="h-full flex overflow-hidden pt-2">
            <div
                className="h-full overflow-auto flex-none flex justify-between border-solid border-2 border-sky-500"
                id="gantt-histogram-options"
            >
                <div className="w-5/6" id="gantt-histogram-chart-options">
                    <ChartOptions />
                </div>
                <div className="w-1/6 flex justify-end overflow-hidden">
                    <YAxisDrawer />
                </div>
            </div>

            <div
                className="h-full border-solid overflow-auto scrollbar-hide border-2 border-sky-500"
                id="gantt-histogram-area"
                
            >
                <div id="gantt-histogram-container" className="h-full">
                    <GanttHistogramChart />
                </div>
            </div>
        </div>
    );
};

export default GanttViewChartContainer;
