import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import moment from 'moment';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './redux/store';

import './assets/styles/App.less';
import './index.css';
import './i18n';

import './helpers/array-prototype';

import App from './App';
import reportWebVitals from './reportWebVitals';
import storeLogsLocally from './logger';
import ErrorFallback from './pages/ErrorFallback';

// Configure moment locale before any components render
moment.defineLocale(window.localStorage.getItem('lng') || 'fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7  // First week of year must contain 1 January (7 + 1 - 1)
    }
});

const stringText = `   _|_|_|                        _|      _|          _|      _|                                                              
_|          _|_|_|  _|_|_|    _|_|_|_|_|_|_|_|      _|_|  _|_|    _|_|_|  _|_|_|      _|_|_|    _|_|_|    _|_|    _|  _|_|  
_|  _|_|  _|    _|  _|    _|    _|      _|          _|  _|  _|  _|    _|  _|    _|  _|    _|  _|    _|  _|_|_|_|  _|_|      
_|    _|  _|    _|  _|    _|    _|      _|          _|      _|  _|    _|  _|    _|  _|    _|  _|    _|  _|        _|        
  _|_|_|    _|_|_|  _|    _|      _|_|    _|_|      _|      _|    _|_|_|  _|    _|    _|_|_|    _|_|_|    _|_|_|  _|        
                                                                                                    _|                      
                                                                                                _|_|      `;

/* eslint-disable-next-line */
console.log('%c' + stringText, 'color: yellow');
localStorage.removeItem('consoleLogs');
if (process.env.REACT_APP_STAGE === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_STAGE,
        integrations: [new Integrations.BrowserTracing()],

        // Set traceSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    console.log = (...args) => {
        storeLogsLocally(`Log: ${args.map((arg) => JSON.stringify(arg)).join(' ')}`);
    };
    const errors = console.error.bind(console);

    console.error = (...args) => {
        errors(...args);
        storeLogsLocally(`Error: ${args.map((arg) => JSON.stringify(arg)).join(' ')}`);
    };
} else {
    // Capture logs
    const logs = console.log.bind(console);
    const errors = console.error.bind(console);

    // Override console.log and console.error
    console.log = (...args) => {
        logs(...args);
        storeLogsLocally(`Log: ${args.map((arg) => JSON.stringify(arg)).join(' ')}`);
    };

    console.error = (...args) => {
        errors(...args);
        storeLogsLocally(`Error: ${args.map((arg) => JSON.stringify(arg)).join(' ')}`);
    };
}

if (process.env.REACT_APP_STAGE === 'staging') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN_STAGING,
        environment: process.env.REACT_APP_STAGE,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
    <ConfigProvider locale={frFR}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App />
        </ErrorBoundary>
    </ConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
