import moment from 'moment';
/* eslint no-unused-vars: "off" */
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import { dateToJsDate } from '../../helpers/planning';
// LAYERS
const addFreeSlack = (task, parameter) => {
    if (!parameter?.showFreeSlack || task.isExtra || task.isResource || task.virtual) return null;
    if (window.ganttInstance.getState().drag_mode === 'resize' || window.ganttInstance.getState().drag_mode === 'move')
        return null;
    if (task.freeMargin) {
        const taskEnd = new Date(task.end_date);
        const freeMargin = dateToJsDate(task.freeMargin, null, false);
        const startDate = taskEnd <= freeMargin ? taskEnd : freeMargin;
        const endDate = taskEnd <= freeMargin ? freeMargin : taskEnd;
        const freeSizes = window.ganttInstance.getTaskPosition(task, startDate, endDate);
        // Affichage des marges libres
        const e1 = document.createElement('div');
        e1.classList.add('freeSlack');
        // task project, hide slack
        if (task.type === window.ganttInstance.config.types.customProject) {
            e1.classList.add('hidden');
        }
        e1.style.background = `${parameter?.freeSlackColor ?? ''}`;
        e1.style.opacity = '0.5';
        e1.style.left = ''.concat(freeSizes.left, 'px');
        e1.style.top = ''.concat(freeSizes.top + (freeSizes.rowHeight - freeSizes.height) / 2, 'px');
        e1.style.width = ''.concat(freeSizes.width, 'px');
        e1.style.height = ''.concat(freeSizes.height / 2, 'px');
        return e1;
    }
    return null;
};

const addTotalSlack = (task, parameter) => {
    if (!parameter?.showTotalSlack || task.isExtra || task.isResource || task.virtual) return null;
    if (window.ganttInstance.getState().drag_mode === 'resize' || window.ganttInstance.getState().drag_mode === 'move')
        return null;

    if (task.totalMargin) {
        const taskEnd = new Date(task.end_date);
        const totalMargin = dateToJsDate(task.totalMargin, null, false);
        const startDate = taskEnd <= totalMargin ? taskEnd : totalMargin;
        const endDate = taskEnd <= totalMargin ? totalMargin : taskEnd;
        const totalSizes = window.ganttInstance.getTaskPosition(task, startDate, endDate);

        // Affichage des marges totales
        const e2 = document.createElement('div');
        e2.classList.add('totalSlack');
        // task cproject, hide slack
        if (task.type === window.ganttInstance.config.types.customProject) {
            e2.classList.add('hidden');
        }
        e2.style.background = `${parameter?.totalSlackColor ?? ''}`;
        e2.style.opacity = '0.5';
        e2.style.left = ''.concat(totalSizes.left, 'px');
        e2.style.top = ''.concat(
            totalSizes.top + (totalSizes.rowHeight - totalSizes.height) / 2 + totalSizes.height / 2,
            'px'
        );
        e2.style.width = ''.concat(totalSizes.width, 'px');
        e2.style.height = ''.concat(totalSizes.height / 2, 'px');
        return e2;
    }
    return null;
};

const addPeriodMarker = (task, tabPreferences) => {
    // if (!parameter?.showTotalSlack || task.isExtra || task.isResource || task.virtual) return null;
    // if (window.ganttInstance.getState().drag_mode === 'resize' || window.ganttInstance.getState().drag_mode === 'move')
    //     return null;
    const periodsContainer = document.createElement('div');

    const periodMarkers = tabPreferences?.gantt_markers ?? [];
    periodMarkers
        .filter((i) => i.show && i.markerStartDate)
        .forEach((marker) => {
            if (marker.markerStartDate && marker.markerEndDate) {
                const periodSizes = window.ganttInstance.getTaskPosition(
                    task,
                    new Date(marker.markerStartDate),
                    new Date(marker.markerEndDate)
                );

                // Affichage des marges totales
                const e2 = document.createElement('div');
                e2.classList.add('tasklayer-container');

                // task cproject, hide slack
                // if (task.type === window.ganttInstance.config.types.customProject) {
                //     e2.classList.add('hidden');
                // }
                e2.style.background = `${marker?.lineColor ?? 'red'}`;
                e2.style.borderLeft = `black 1px solid`;
                e2.style.borderRight = `black 1px solid`;
                e2.style.opacity = '0.5';
                e2.style.left = ''.concat(periodSizes.left, 'px');
                e2.style.top = ''.concat(periodSizes.top, 'px');
                e2.style.width = ''.concat(periodSizes.width, 'px');
                e2.style.height = ''.concat(periodSizes.rowHeight, 'px');
                e2.setAttribute(
                    'title',
                    `${marker.markerName}: ${moment(marker.markerStartDate).format('DD/MM/yyyy')} - ${moment(
                        marker.markerEndDate
                    ).format('DD/MM/yyyy')}`
                );

                periodsContainer.append(e2);
                if (task.id === 'virtual_top') {
                    const divIndicator = document.createElement('div');
                    divIndicator.classList.add('tasklayer-container');
                    divIndicator.style.background = `${marker?.lineColor ?? 'red'}`;
                    divIndicator.style.color = `${marker?.fontColor ?? 'red'}`;
                    divIndicator.style.padding = `3px`;
                    divIndicator.style.opacity = '0.5';
                    divIndicator.style.fontSize = '12px';
                    divIndicator.style.width = ''.concat(periodSizes.width, 'px');
                    divIndicator.style.textAlign = 'center';

                    divIndicator.style.left = ''.concat(periodSizes.left, 'px');
                    divIndicator.style.top = '0px';
                    divIndicator.append(`${marker.markerName}`);
                    periodsContainer.append(divIndicator);
                }
            }
        });
    return periodsContainer;
};
// Layer fil rouge
const addAvancementLayer = (task, parameter) => {
    if (!parameter?.showProgressLine) return null;
    if (window.ganttInstance.getState().drag_mode === 'resize' || window.ganttInstance.getState().drag_mode === 'move')
        return null;

    // On récupère la position actuelle de la barre
    const currentTaskSizes = window.ganttInstance.getTaskPosition(
        task,
        new Date(task.start_date),
        new Date(task.end_date)
    );
    const avancementTop = currentTaskSizes.top;
    let avancementLeft = currentTaskSizes.left;
    // Calculer la largeur de l'avancement
    const currentTaskProgressWidth = currentTaskSizes.width * task.progress;
    // ajouter la largeur au point d'avancement actuel
    avancementLeft += currentTaskProgressWidth;
    let taskAvancementLeft = avancementLeft;

    // Affichage de la ligne de date
    let divLigneDate = '';
    let divIndicator = '';
    let progressSize;
    if (parameter?.progressDate) {
        progressSize = window.ganttInstance.getTaskPosition(
            task,
            new Date(parameter?.progressDate),
            new Date(task.end_date)
        );
        // si on est sur la première tâche virtuelle en haut, la position devient par rapport à la date de progression
        if (task.id === 'virtual_top' || task.isResource) {
            avancementLeft = progressSize.left;
        }
        divLigneDate = document.createElement('div');
        divLigneDate.classList.add('tasklayer-container');

        divLigneDate.style.borderLeft = `2px dashed ${parameter?.progressLineColor ?? 'red'}`;
        divLigneDate.style.opacity = '1';
        divLigneDate.setAttribute('title', `${moment(parameter.progressDate).format('DD/MM/yyyy HH:mm')}`);
        // divLigneDate.style.zIndex = '2';
        divLigneDate.style.left = ''.concat(progressSize.left, 'px');
        divLigneDate.style.top = ''.concat(progressSize.top, 'px');
        // divLigneDate.style.width = '2px';
        divLigneDate.style.height = ''.concat(currentTaskSizes.rowHeight, 'px');
        // Afficher la date si première tâche virtuelle
        if (task.id === 'virtual_top') {
            divIndicator = document.createElement('div');
            divIndicator.classList.add('tasklayer-container');
            divIndicator.style.background = `${parameter?.progressLineColor ?? 'red'}`;
            divIndicator.style.color = `#fff`;
            divIndicator.style.padding = `3px`;
            divIndicator.style.opacity = '0.8';
            divIndicator.style.fontSize = '12px';
            divIndicator.style.left = ''.concat(progressSize.left, 'px');
            divIndicator.style.top = '0px';
            divIndicator.append(`${moment(parameter.progressDate).format('DD/MM/yyyy')}`);
        }
    }

    // Affichage du div vertical
    let divVertical = '';
    if (!task.isExtra && !task.isResource) {
        divVertical = document.createElement('div');
        divVertical.classList.add('tasklayer-container');

        divVertical.style.background = `${parameter?.progressLineColor ?? 'red'}`;
        divVertical.style.opacity = '1';
        divVertical.style.zIndex = '1';
        if (
            (task.progress === 1 && progressSize.left > taskAvancementLeft) ||
            (task.progress === 0 && progressSize.left < taskAvancementLeft)
        ) {
            taskAvancementLeft = progressSize.left;
            avancementLeft = progressSize.left;
        }
        divVertical.style.left = ''.concat(taskAvancementLeft, 'px');
        divVertical.style.top = ''.concat(avancementTop, 'px');
        divVertical.style.width = '2px';
        divVertical.style.height = ''.concat(currentTaskSizes.rowHeight, 'px');
    }
    if (window.ganttInstance.getNext(task.id)) {
        // Récupérer la position de la tâche suivante
        const nextTask = window.ganttInstance.getTask(window.ganttInstance.getNext(task.id));
        const nextTaskSizes = window.ganttInstance.getTaskPosition(
            nextTask,
            new Date(nextTask.start_date),
            new Date(nextTask.end_date)
        );
        // Calculer la largeur de l'avancement
        const nextTaskProgressWidth = nextTaskSizes.width * nextTask.progress;
        let nextTaskAvancementLeft = nextTaskSizes.left + nextTaskProgressWidth;
        // Si on est sur la dernière tâche virtuelle, on se réfère à la position de la date de progression
        if (nextTask.id === 'virtual_bottom' || nextTask.isResource) {
            progressSize = window.ganttInstance.getTaskPosition(
                nextTask,
                new Date(parameter?.progressDate),
                new Date(nextTask.end_date)
            );
            nextTaskAvancementLeft = progressSize.left;
        }
        if (
            (nextTask.progress === 1 && nextTaskAvancementLeft < progressSize.left) ||
            (nextTask.progress === 0 && nextTaskAvancementLeft > progressSize.left)
        ) {
            nextTaskAvancementLeft = progressSize.left;
        }
        // Calculer la différence entre la posiion des avancement des 2 tâches pour avaoir la largeur du div
        const avancementWidth = Math.abs(avancementLeft - nextTaskAvancementLeft);
        // si l'avancecement de la dauxième tâche se trouve à gauche de la première alors on recule la position d'avancement de la largeur du div avancement
        if (nextTaskAvancementLeft < avancementLeft) {
            avancementLeft -= avancementWidth;
        }
        // Affichage du div horizontal
        const divHorizontal = document.createElement('div');
        divHorizontal.classList.add('tasklayer-container');

        divHorizontal.style.background = `${parameter?.progressLineColor ?? 'red'}`;
        divHorizontal.style.opacity = '1';
        divHorizontal.style.zIndex = '2';
        divHorizontal.style.left = ''.concat(avancementLeft, 'px');
        divHorizontal.style.top = ''.concat(avancementTop + currentTaskSizes.rowHeight, 'px');
        divHorizontal.style.width = ''.concat(avancementWidth + 2, 'px');
        divHorizontal.style.height = '2px';
        // divHorizontal.style.height = ''.concat(currentTaskSizes.rowHeight - currentTaskSizes.height , 'px');
        const render = document.createElement('div');
        render.append(divIndicator, divLigneDate, divVertical, divHorizontal);
        return render;
    }
    const render = document.createElement('div');
    render.append(divIndicator, divLigneDate, divVertical);
    return render;
};

const handleEditResourceCount = (e) => {
    const node = e.target;
    const val = node.innerHTML;
    const input = document.createElement('input');
    input.style.width = '30px';
    input.style.textAlign = 'center';

    input.value = val;
    input.onblur = (inputEvent) => {
        const newValue = inputEvent.target.value;
        // eslint-disable-next-line
        inputEvent.target.parentNode.innerHTML = newValue;
    };
    node.innerHTML = '';
    node.appendChild(input);
    input.focus();
};

const resourceTaskLayer = (task) => {
    const html = document.createElement('div');
    if (task.isResource && task.slices.length > 0) {
        task.slices.forEach((slice) => {
            const sliceSizes = window.ganttInstance.getTaskPosition(
                task,
                dateToJsDate(slice.startDate, null, false),
                dateToJsDate(slice.endDate, null, false)
            );
            const resourceInfoDiv = document.createElement('div');
            resourceInfoDiv.classList.add('tasklayer-container');
            resourceInfoDiv.classList.add('resource_task');

            resourceInfoDiv.style.display = `flex`;
            resourceInfoDiv.style.color = `red`;
            resourceInfoDiv.style.justifyContent = `center`;
            resourceInfoDiv.style.alignItems = `center`;
            resourceInfoDiv.style.borderRadius = '5px';
            resourceInfoDiv.style.overflow = 'hidden';
            resourceInfoDiv.style.marginTop = ''.concat((sliceSizes.rowHeight - sliceSizes.height) / 2, 'px');

            // resourceInfoDiv.style.zIndex = '2';
            resourceInfoDiv.style.left = ''.concat(sliceSizes.left, 'px');
            resourceInfoDiv.style.top = ''.concat(sliceSizes.top, 'px');
            resourceInfoDiv.style.width = ''.concat(sliceSizes.width, 'px');
            resourceInfoDiv.style.height = ''.concat(sliceSizes.height, 'px');
            const resourceInfoText = document.createElement('div');
            resourceInfoText.style.padding = '3px';
            resourceInfoText.style.zIndex = '2';
            resourceInfoText.style.borderRadius = '5px';
            
            if (task.isSkillTask) {
                resourceInfoText.append(`${slice.quantity}/${slice.neededQuantity}`);
                resourceInfoDiv.title = `${slice.quantity}/${slice.neededQuantity}`;

                if (slice.quantity < slice.neededQuantity) {

                    resourceInfoText.style.color = 'orange';
                }
                if (slice.quantity === slice.neededQuantity) {

                    resourceInfoText.style.color = 'green';
                }
                if (slice.quantity > slice.neededQuantity) {

                    resourceInfoText.style.color = 'mediumblue';
                }
                if (slice.quantity < slice.neededQuantity && slice.quantity === 0) {
                    
                    resourceInfoText.style.color = 'red';
                }
            } else {
                // resourceInfoText.style.color = 'white';
                resourceInfoText.append(slice.quantity);
                resourceInfoDiv.title = slice.quantity;
                resourceInfoText.ondblclick = handleEditResourceCount;
            }
            resourceInfoDiv.append(resourceInfoText);
            html.append(resourceInfoDiv);
        });
        // }
    }
    return html;
};


const needsTaskLayer = (task) => {
    const html = document.createElement('div');
    if (task.isResource) {
        (task.slices || []).forEach((slice) => {
            const affectationSize = window.ganttInstance.getTaskPosition(
                task,
                dateToJsDate(slice.start, null, false),
                dateToJsDate(slice.end, null, false)
            );
            const e1 = document.createElement('div');
            e1.classList.add('tasklayer-container');
            e1.classList.add('resource_task');
            e1.classList.add(task.class);
            e1.title = task.quantity;
            e1.style.left = ''.concat(affectationSize.left, 'px');
            e1.style.top = ''.concat(affectationSize.top, 'px');
            e1.style.width = ''.concat(affectationSize.width, 'px');
            e1.style.height = ''.concat(affectationSize.height * 0.8, 'px');
            e1.style.marginTop = ''.concat((affectationSize.rowHeight - affectationSize.height) / 2, 'px');
            e1.style.display = 'flex';
            e1.style.justifyContent = 'center';
            e1.style.alignItems = 'center'
            e1.style.borderRadius = '5px';
            e1.append(task.quantity)
            html.append(e1);
        })
            
    }
    return html;
};

const baseLineLayer = (task) => {
    if (task.isExtra || task.isResource || task.virtual || (window.ganttInstance.isSplitTask(task) && !task.$open))
        return null;
    if (task.planned_start && task.planned_end) {
        const sizes = window.ganttInstance.getTaskPosition(
            task,
            dateToJsDate(task.planned_start, null, false),
            dateToJsDate(task.planned_end, null, false)
        );
        const el = document.createElement('div');
        el.classList.add('tasklayer-container');
        el.style.background = 'lightblue';

        el.style.left = ''.concat(
            task.type !== 'milestone' ? sizes.left : sizes.left - window.ganttInstance.config.bar_height / 6,
            'px'
        );
        el.style.width = ''.concat(
            task.type !== 'milestone' ? sizes.width : window.ganttInstance.config.bar_height / 3,
            'px'
        );
        el.style.top = ''.concat(
            task.type !== 'milestone'
                ? sizes.top + (sizes.rowHeight - window.ganttInstance.config.row_height / 2) / 2 + sizes.height / 2
                : sizes.top +
                      (sizes.rowHeight - window.ganttInstance.config.row_height / 2) / 2 +
                      sizes.height / 2 +
                      window.ganttInstance.config.bar_height / 6,
            'px'
        );
        el.style.height = ''.concat(
            task.type !== 'milestone'
                ? window.ganttInstance.config.bar_height / 2
                : window.ganttInstance.config.bar_height / 3,
            'px'
        );
        // milestone
        if (sizes.width === 0 && task.type === 'milestone') {
            el.style.transform = 'rotate(-45deg)';
        } else {
            // adding progressbar
            const progress = document.createElement('div');
            // progress.className = 'baseline_progressbar';
            progress.title = task.baseline_progress.toString().concat('%');
            progress.style.background = 'black';
            progress.style.opacity = '0.15';
            progress.style.height = ''.concat(window.ganttInstance.config.bar_height / 2, 'px');
            progress.style.left = ''.concat(sizes.left, 'px');
            progress.style.width = ''.concat(task.baseline_progress, '%');
            progress.style.top = ''.concat(
                sizes.top + (sizes.rowHeight - window.ganttInstance.config.row_height / 2) / 2 + sizes.height / 2,
                'px'
            );
            el.appendChild(progress);
        }
        return el;
    }
    return false;
};

const addGanttTaskLayers = (tabPreferences, i18n) => {
    const parameter = tabPreferences?.gantt_parameters;
    // Ajout des layers apres l'initialisation
    window.ganttInstance.addTaskLayer((task) => addFreeSlack(task, parameter));
    window.ganttInstance.addTaskLayer((task) => addTotalSlack(task, parameter));
    window.ganttInstance.addTaskLayer((task) => addPeriodMarker(task, tabPreferences));
    // Layer sur le fil rouge
    window.ganttInstance.addTaskLayer((task) => addAvancementLayer(task, parameter));
    // Layer ressource
    const resourceViewModeObject = RESOURCE_VIEW_MODE(i18n);
    switch (tabPreferences?.gantt_parameters?.showResources) {
        case resourceViewModeObject.NOTHING.value:
            
            break;
        case resourceViewModeObject.NEEDS.value:
            window.ganttInstance.addTaskLayer((task) => needsTaskLayer(task));
            break;
        //
    
        default:
            break;
    }
    if (tabPreferences?.gantt_baseline?.showBaseLine) {
        window.ganttInstance.addTaskLayer((task) => baseLineLayer(task));
    }
};

export default { addGanttTaskLayers };
