import { formatGanttTask } from './planning';
import { getTextColor } from './text-color';
import store from '../redux/store';
import taskStripes from './task-stripes';
import calculateTaskDuration from '../redux/utils/ActivitySliceUtils';
import { selectTimeUnitById } from '../redux/slices/app/calendar.slice';
import { setTasksStripesStyles } from '../redux/slices/app/planning.slice';

export const updateTaskStripes = (dispatch) => {
    const stripedTasks = window.ganttInstance
        .getTaskByTime()
        .filter(taskStripes.hasStripes)
        .map(taskStripes.formatForState);
    dispatch(setTasksStripesStyles(stripedTasks));
};

export const getTaskColor = (task, parameters) => {
    if (task.startDate === task.endDate) return parameters?.milestoneColor;
    return task.subActivitiesId.length > 0 ? parameters?.parentTaskBarColor : parameters?.simpleTaskBarColor;
};

export const  updateGanttTask = (task, tabPreferences) => {
    const durationUnit = selectTimeUnitById(store.getState(), task.dayDefinitionId);
    const taskDuration = calculateTaskDuration(task, durationUnit);
    const taskColor = getTaskColor(task, tabPreferences.gantt_parameters);

    return formatGanttTask(task, {
        durationApi: taskDuration,
        roundedDuration: taskDuration ? Number(taskDuration).toFixed(1) : 0,
        realChildren: task.subActivitiesId,
        color: taskColor,
        textColor: getTextColor(taskColor),
        parent: task.parent,
        parentId: task.activityParentId,
    });
};


export const formatNewGanttTask = (task, tabPreferences) => {
    const durationUnit = selectTimeUnitById(store.getState(), task.dayDefinitionId);
    const taskDuration = calculateTaskDuration(task, durationUnit);
    const taskColor = getTaskColor(task, tabPreferences.gantt_parameters);
    const parentFakeId = window.ganttInstance.getTaskByServerId(task.activityParentId)?.id;
    
    return formatGanttTask(task, {
        durationApi: taskDuration,
        realChildren: [],
        color: taskColor,
        textColor: getTextColor(taskColor),
        parent: parentFakeId,
        parentId: task.activityParentId,
    });
};

/**
 * Formats the link data for Gantt
 * @param {Object} link - The link data
 * @returns {Object} - The Gantt link data
 */
export const updateGanttLink = (link) => {
    const ganttLink = {
        type: { FD: 0, DD: 1, FF: 2, DF: 3 }[link.type],
        color: link.category.color,
        dataAPI: link,
    }
    return ganttLink;
}